const TYPES = ['belegde-broodjes.svg', 'koffie.svg', 'vers-brood.svg', 'vers-gebak.svg', 'schepijs.svg']

export default function BackgroundWithIcons() {
  return (
    <div className="w-[250px] h-[150px] sm:w-[350px] sm:h-[200px] md:w-[500px] md:h-[250px] lg:[700px] lg:h-[325px] xl:w-[800px] xl:h-[400px] bg-rc-sandaconda overflow-hidden pointer-events-none clip--triangle">
      <div className="grid grid-cols-7 gap-1 lg:gap-3 -mt-4">
        {Array.from(Array(50).keys()).map((index) => {
          if (index % 2 === 0) return <div key={index} />

          const type = TYPES[index % TYPES.length]

          return (
            <div key={`${index}-${type}`} className="col-span-1">
              <img src={`/static/types/${type}`} className="w-full object-contain" alt="" />
            </div>
          )
        })}
      </div>
    </div>
  )
}
